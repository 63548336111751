@font-face {
  font-family: 'agenfb';
  src: url('../font/AGENCYB.TTF');
  src: url('../font/AGENCYR.TTF');
}

.share {
  margin: 0 auto;
  width: 10rem;
  height: auto;
  padding: 0;
}

.share_link {
  width: 1rem;
  height: 1rem;
  float: right;
  position: absolute;
  top: 4.1rem;
  right: 0.2rem;
  cursor: pointer;
}

.share_link img {
  width: 1rem;
  height: 1rem;
}

.download {
  width: 0.509259rem;
  height: 0.509259rem;
  cursor: pointer;
  position: absolute;
  z-index: 100;
  margin-left: 9.259259rem;
  margin-top: 0.185185rem;
}

.share_all {
  margin: 0 auto;
  width: 10rem;
  height: auto;
  min-height: 16.666667rem;
  padding: 0;
  font-family: "Microsoft YaHei";
}

.share_top {
  width: 10rem;
  height: 5.685185rem;
  background: url('../image/share/title_bg.png') no-repeat;
  background-size: 100% 100%;
  margin: 0;
  padding: 0;
}

.share_top_div {
  width: 5rem;
  height: 5.685185rem;
}

.share_top_div>img {
  width: 3.62037rem;
  height: 4.425926rem;
  margin-top: 0.824074rem;
  margin-left: 0.824074rem;
}

.share_top_div p {
  padding: 0;
  margin: 0;
  width: 4.166667rem;
  height: 0.416667rem;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  clear: both;
  margin-left: 0.203704rem;
}

.share_top_div p.marginTop {
  margin-top: 1.481481rem;
}

.share_top_div p.marginTop_small {
  margin-top: 0.027778rem;
}

.progress-radial {
  width: 2.592593rem;
  height: 2.592593rem;
  border-radius: 50%;
  margin: 0 auto;
  margin-top: 0.537037rem;
}

.progress-radial .overlay {
  position: absolute;
  width: 1.666667rem;
  height: 1.666667rem;
  background-color: #fdffff;
  border-radius: 50%;
  margin-left: 0.462963rem;
  margin-top: 0.462963rem;
  text-align: center;
  line-height: 1.703704rem;
  font-size: 0.777778rem;
}

.circle_blueBg {
  background-color: #72a0fe;
}

.circle_orangeBg {
  background-color: #ffa06d;
}

.share_content {
  width: 9.537037rem;
  height: auto;
  margin: 0 auto;
  padding: 0;
}

.share_game {
  width: 9.537037rem;
  height: 11.388889rem;
  margin-bottom: 0.37037rem;
}

.blueBg {
  background: url('../image/share/blueBg.png') no-repeat;
  background-size: 100% 100%;
}

.orangeBg {
  background: url('../image/share/orangeBg.png') no-repeat;
  background-size: 100% 100%;
}

.game_title {
  width: 9.537037rem;
  height: 1.601852rem;
}

.game_title img {
  width: 1.111111rem;
  height: 1.111111rem;
  float: left;
  margin-left: 0.601852rem;
  margin-top: 0.231481rem;
}

.game_title span {
  width: 6.851852rem;
  height: 1.018519rem;
  float: left;
  color: #ffffff;
  font-size: 0.833333rem;
  font-weight: bold;
  margin-left: 0.37037rem;
  margin-top: 0.277778rem;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
}

.game_today {
  width: 9.537037rem;
  height: 2.851852rem;
  clear: both;
}

.game_today_div {
  width: 4.768519rem;
  height: 2.851852rem;
}

.share_game h1 {
  width: 4.768519rem;
  height: 2.148148rem;
  font-size: 1.277778rem;
  font-weight: normal;
  margin: 0;
  padding: 0;
  line-height: 2.148148rem;
  text-align: center;
}

.share_content h2 {
  margin: 0;
  padding: 0;
  font-size: 0.37963rem;
  font-weight: bold;
  color: #666666;
  width: 4.074074rem;
  height: 0.462963rem;
  line-height: 0.462963rem;
  margin-left: 0.601852rem;
  margin-top: 0.231481rem;
}

.share_content .smallTitle {
  font-size: 0.296296rem;
  color: #888888;
  font-weight: normal;
}

.game_progress {
  width: 9.537037rem;
  height: 4.37037rem;
  clear: both;
}

.game_progress_div {
  width: 4.768519rem;
  height: 4.37037rem;
}

.totalProgress {
  width: 4.768519rem;
  height: 3.675926rem;
}

.todayUpdate {
  width: 4.768519rem;
  height: 1.546296rem;
}

.todayUpdate img {
  width: 0.722222rem;
  height: 0.694444rem;
  margin-left: 2.018519rem;
  margin-top: 0.407407rem;
}

.todayUpgrade {
  width: 4.768519rem;
  height: 1.111111rem;
}

.todayUpgrade img {
  width: 0.481481rem;
  height: 0.453704rem;
}

.todayUpgrade div {
  margin: 0 auto;
  padding: 0;
}

.todayUpgrade .upgrade_1 {
  width: 0.666667rem;
  height: 0.518519rem;
  margin-top: 0.453704rem;
}

.todayUpgrade .upgrade_2 {
  width: 1.222222rem;
  height: 0.518519rem;
  margin-top: 0.453704rem;
}

.todayUpgrade .upgrade_3 {
  width: 1.777778rem;
  height: 0.518519rem;
  margin-top: 0.453704rem;
}

.todayUpgrade .upgrade_4 {
  width: 2.333333rem;
  height: 0.518519rem;
  margin-top: 0.453704rem;
}

.todayUpgrade .upgrade_5 {
  width: 2.888889rem;
  height: 0.518519rem;
  margin-top: 0.453704rem;
}

.todayUpgrade .upgrade_6 {
  width: 2.888889rem;
  height: 0.962963rem;
  margin-top: 0.277778rem;
}

.todayUpgrade div img {
  margin-left: 0.092593rem;
}

.game_time {
  width: 9.537037rem;
  height: 2.555556rem;
  clear: both;
}

.game_time .game_time_div {
  width: 4.768519rem;
  height: 2.555556rem;
}

.game_time .game_time_div .border {
  width: 3.037037rem;
  height: 0.925926rem;
  margin: 0 auto;
  margin-top: 0.416667rem;
  font-size: 0.601852rem;
  text-align: center;
  line-height: 0.925926rem;
}

.game_time .game_time_div .border_blue {
  border: 0.018519rem solid #6595f6;
  border-radius: 0.055556rem;
  color: #6595f6;
}

.game_time .game_time_div .border_orange {
  border: 0.018519rem solid #f07a3a;
  border-radius: 0.055556rem;
  color: #f07a3a;
}

.share_propgress {
  width: 9.537037rem;
  height: 1.203704rem;
  clear: both;
  margin: 0.185185rem 0 0.185185rem 0;
}

.share_propgress .planet_ico {
  width: 1.481481rem;
  height: 1.203704rem;
}

.share_propgress .planet_ico img {
  width: 0.925926rem;
  height: 0.925926rem;
  margin-left: 0.555556rem;
  margin-top: 0.166667rem;
}

.planet_progress {
  width: 6.203704rem;
  height: 1.203704rem;
}

.planet_progress>h2 {
  font-size: 0.333333rem;
  font-weight: bold;
}

.planet_progress>h2 .progressTitle {
  font-weight: normal;
}

.planet_progress .progress_border {
  width: 5.37037rem;
  height: 0.148148rem;
  background-color: #ebebeb;
  border-radius: 0.083333rem;
  margin-left: 0.592593rem;
  margin-top: 0.092593rem;
}

.progress_border_green {
  width: 2.777778rem;
  height: 0.148148rem;
  background-color: #7ed133;
  border-radius: 0.083333rem;
  float: left;
  z-index: 100;
  position: absolute;
}

.progress_border_blue {
  width: 0.740741rem;
  height: 0.148148rem;
  background-color: #72a0ff;
  border-radius: 0.083333rem;
  float: left;
}

.progress_total {
  width: 1.277778rem;
  height: 1.203704rem;
  font-size: 0.444444rem;
  color: #666666;
  line-height: 1.648148rem;
}

.progress_tip {
  color: #72a0ff;
  font-size: 0.444444rem;
  position: absolute;
  z-index: 101;
  margin-top: 0.111111rem;
  margin-left: 0.231481rem;
  font-weight: normal;
}

.fontColor_blue {
  color: #6595f6;
}

.fontColor_orage {
  color: #ff813d;
}

.fontColor_white {
  color: #ffffff;
}

.fontColor_grey {
  color: #c2d2ff;
}

.fontColor_green {
  color: #10be5e;
}

.fontColor_greyBlack {
  color: #797979;
}

.fontSize_max {
  font-size: 0.333333rem;
}

.fontSize_small {
  font-size: 0.222222rem;
}

.fontSize_weight {
  font-weight: 800;
}

.fontNumber {
  font-family: "agenfb";
}

.left {
  float: left;
}