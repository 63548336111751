.tip {
  width: 100%;
  height: auto;
}

.tip_img {
  width: 2rem;
  height: 2rem;
  margin: 0 auto;
  padding-top: 1rem;
}

.tip_img img {
  width: 2rem;
  height: 2rem;
}

.tip_msg {
  width: 100%;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  font-size: 0.5rem;
}