body {
  margin: 0;
  padding: 0;
}

.formBody {
  width: 100%;
  height: auto;
  background-color: #4466a7;
  padding-bottom: 0.5rem;
}

.activelogo {
  width: 100%;
  height: 1.2rem;
}

.formBody h2 {
  width: 4.5rem;
  height: 1.2rem;
  line-height: 1.2rem;
  text-align: center;
  margin: 0 auto;
  color: #ffffff;
  border: 0.018519rem solid #ffffff;
  border-radius: 0.092593rem;
  font-size: 0.7rem;
}

.formContent {
  width: 8rem;
  height: auto;
  margin: 0 auto;
  margin-top: 1rem;
  border: 0.046296rem solid #ffffff;
}

.formContent .formRow {
  width: 100%;
  height: 2.4rem;
  margin-top: 0.2rem;
}

.formRow label {
  width: 100%;
  height: 1rem;
  line-height: 1rem;
  display: table;
  font-size: 0.4rem;
  color: #fff;
  font-weight: 600;
  padding-left: 0.5rem;
}

.formRow input {
  width: 6.5rem;
  height: 1rem;
  line-height: 1rem;
  border: 0.046296rem solid #ffffff;
  border-radius: 0.092593rem;
  margin-top: 0.2rem;
  margin-left: 0.5rem;
  font-size: 0.5rem;
  padding-left: 0.3rem;
}

.formRadio {
  width: 7.8rem;
  height: auto;
}

.formRadio .rowtitle {
  width: 100%;
  height: 1rem;
  line-height: 1rem;
  display: table;
  font-size: 0.4rem;
  color: #fff;
  font-weight: 600;
  padding-left: 0.5rem;
}

.radioBoder {
  width: 6.8rem;
  height: auto;
  line-height: 1rem;
  border: 0.046296rem solid #ffffff;
  border-radius: 0.092593rem;
  margin-top: 0.2rem;
  margin-left: 0.5rem;
  background-color: #ffffff;
}

.radioBoder input {
  width: 0.4rem;
  height: 0.4rem;
  margin-left: 0.4rem;
  margin-top: 0.3rem;
}

.radioBoder p {
  margin: 0;
  padding: 0;
  color: #8b5999;
  clear: both;
}

.radioBoder label {
  font-size: 0.37rem;
  margin-left: 0.3rem;
}

.radioBottom {
  border-bottom: 0.018519rem solid #979797;
}

.formRemak {
  width: 100%;
  height: auto;
}

.formRemak .rowtitle {
  width: 100%;
  height: 1rem;
  line-height: 1rem;
  text-align: center;
  font-size: 0.4rem;
  color: #fff;
  font-weight: 600;
  display: table;
}

.formRemak textarea {
  width: 6.5rem;
  height: 3rem;
  border: 0.046296rem solid #ffffff;
  border-radius: 0.092593rem;
  margin-top: 0.2rem;
  margin-left: 0.5rem;
  font-size: 0.5rem;
  padding-left: 0.3rem;
  padding-top: 0.3rem;
}

.formButton {
  width: 7rem;
  height: 3.3rem;
  margin-top: 0.5rem;
  margin: 0 auto;
}

.formButton input {
  width: 7rem;
  height: 1.2rem;
  line-height: 1.2rem;
  border: none;
  letter-spacing: 0.092593rem;
  font-size: 0.4rem;
  margin: 0.5rem 0;
}

.formButton .enable {
  background-color: #ffb350;
  font-weight: bold;
  color: #2f0606;
  width: 6.9rem;
  height: 1.2rem;
  line-height: 1.2rem;
  text-align: center;
  display: block;
  letter-spacing: 0.092593rem;
  font-size: 0.4rem;
  border-radius: 0.092593rem;
  margin: 0;
  margin-top: 0.7rem;
}

.formButton .unable {
  background-color: #d6d2d2;
  font-weight: bold;
  color: #2f0606;
  width: 6.9rem;
  height: 1.2rem;
  line-height: 1.2rem;
  text-align: center;
  display: block;
  letter-spacing: 0.092593rem;
  font-size: 0.4rem;
  border-radius: 0.092593rem;
  margin: 0;
  margin-top: 0.7rem;
}

.hideDiv {
  display: none;
}

.bigHeight {
  height: 4.5rem;
}

.centerHeight {
  height: 3.2rem;
}

.smallHeight {
  height: 2.2rem;
}

.radioMager {
  margin-top: 0.6rem;
}

.formFooter {
  width: 100%;
  height: 1.5rem;
  font-size: 0.4rem;
  line-height: 0.5rem;
  color: #ffffff;
  font-weight: 700;
  text-align: center;
  margin-top: 0.5rem;
}

.formFooter h3 {
  width: 100%;
  height: 0.6rem;
  margin: 0;
  line-height: 0.6rem;
}

.mapLocation {
  width: 6rem;
  height: 3rem;
  margin: 0 auto;
}

.mapgo {
  width: 6rem;
  height: 0.5rem;
  margin: 0 auto;
  background-color: #ffffff;
  clear: both;
}

.mapgo span {
  width: 1.5rem;
  height: 0.5rem;
  text-align: center;
  line-height: 0.5rem;
  float: left;
}

.mapgo a {
  width: 1.2rem;
  height: 0.4rem;
  float: right;
  background-color: #42009b;
  text-align: center;
  line-height: 0.4rem;
  color: #ffffff;
  margin-top: 0.05rem;
  margin-right: 0.2rem;
  border-radius: 0.074074rem;
  cursor: pointer;
}

.success {
  width: 8rem;
  height: auto;
  margin: 0 auto;
}

.rowline {
  width: 100%;
  height: 1rem;
  clear: both;
}

.rowleft {
  width: 50%;
  float: left;
}

.rowline h4 {
  margin: 0;
}

.studentInfo ul {
  margin: 0;
  padding: 0;
}

.studentInfo ul li {
  list-style: none;
  height: 0.7rem;
  font-size: 0.34rem;
  font-weight: bold;
}

.backBtn {
  background-color: #48b592;
  font-weight: bold;
  color: #000000;
  width: 6.9rem;
  height: 1.2rem;
  line-height: 1.2rem;
  text-align: center;
  display: block;
  letter-spacing: 0.092593rem;
  font-size: 0.4rem;
  border-radius: 0.092593rem;
  margin: 0;
  border: none;
  margin-top: 0.5rem;
}

.formInfo h1 {
  font-size: 0.7rem;
  text-align: center;
}

.formInfo h2 {
  font-size: 0.6rem;
  text-align: center;
  height: 1rem;
  line-height: 1rem;
}

.rowright {
  float: left;
  width: 50%;
}

.search {
  width: 12rem;
  height: auto;
  margin: 0 auto;
}

.search h2 {
  width: 100%;
  height: 1rem;
  line-height: 1rem;
  font-size: 0.5rem;
}

.searchIn {
  font-size: 0.3rem;
  margin-top: 1rem;
}

.searchIn button {
  width: 3.4rem;
  height: 0.6rem;
  margin-left: 1.5rem;
}

.studentInfo {
  width: 9rem;
  height: 1.3rem;
  margin: 0 auto;
  font-size: 0.4rem;
  margin-top: 1rem;
}

.remarkright {
  width: 100%;
  height: auto;
  font-size: 0.35rem;
  margin: 0;
  font-weight: bold;
}

.courselist {
  width: 100%;
  height: 1.3rem;
  font-size: 0.4rem;
  font-weight: bold;
  text-align: center;
  margin-top: 0.3rem;
}

.searchRemark {
  width: 100%;
  height: auto;
  font-size: 0.4rem;
}

.formInfo {
  width: 8rem;
  height: auto;
  margin: 0 auto;
}

.courselist h5 {
  margin: 0;
  font-size: 0.4rem;
}

.courselist h6 {
  margin: 0;
  font-size: 0.35rem;
  height: 0.5rem;
  margin-top: 0.2rem;
}

.courselist h6 span {
  color: crimson;
}

.lineTop {
  width: 8rem;
  height: 1.2rem;
  border-top: 0.009259rem solid #bbb;
  font-size: 0.6rem;
  line-height: 1.2rem;
  text-align: right;
  margin-top: 0.2rem;
}

.lineTop span {
  color: crimson;
  font-weight: bold;
}