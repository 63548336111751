.error {
  overflow-x: hidden;
}

.error .title {
  font-size: 6rem;
  text-align: center;
  color: #030749;
  font-weight: 700;
  margin-bottom: 1rem;
}

.error .descript {
  font-size: 1.5rem;
  text-align: center;
  color: #4a4a4a;
  font-weight: 700;
  margin-top: 0;
  padding-left: 1.5rem;
}