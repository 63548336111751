.payBody {
  width: 100%;
}

.payBody h2 {
  font-size: 0.5rem;
  text-align: center;
  width: 100%;
  height: 2rem;
  margin: 0;
  line-height: 2rem;
}

.payBody h1 {
  font-size: 0.5rem;
  text-align: center;
  width: 100%;
  height: 1rem;
  margin: 0;
  line-height: 1rem;
}

.payBody h1 span {
  color: red;
  font-size: 0.8rem;
  font-weight: bold;
}

.payButton {
  width: 4rem;
  height: 2rem;
  margin: 0 auto;
  padding-top: 0.9rem;
}

.payButton button {
  width: 4rem;
  height: 1rem;
  font-size: 0.45rem;
  font-weight: bold;
  color: #ffffff;
  background-color: #07c160;
  border: none;
  border-radius: 0.037037rem;
}

.paySuccess {
  width: 100%;
  height: 5rem;
  line-height: 5rem;
  text-align: center;
  font-size: 0.6rem;
}