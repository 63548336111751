@font-face {
  font-family: 'agenfb';
  src: url('../font/AGENCYB.TTF');
  src: url('../font/AGENCYR.TTF');
}

.share {
  margin: 0 auto;
  width: 10rem;
  height: auto;
  padding: 0;
}

.share_link {
  width: 1rem;
  height: 1rem;
  float: right;
  position: absolute;
  top: 4.1rem;
  right: 0.2rem;
  cursor: pointer;
}

.share_link img {
  width: 1rem;
  height: 1rem;
}

#sub h3 {
  width: 100%;
  height: 0.5rem;
  font-size: 0.4rem;
  line-height: 0.2rem;
  border-bottom: 0.009259rem solid #797979;
}

#parent h3 {
  width: 100%;
  height: 0.5rem;
  font-size: 0.4rem;
  line-height: 0.2rem;
  border-bottom: 0.009259rem solid #797979;
}

.download {
  width: 0.509259rem;
  height: 0.509259rem;
  cursor: pointer;
  position: absolute;
  z-index: 100;
  margin-left: 9.259259rem;
  margin-top: 0.185185rem;
}

.share_all {
  margin: 0 auto;
  width: 10rem;
  height: auto;
  min-height: 16.666667rem;
  padding: 0;
  font-family: "Microsoft YaHei";
}

.share_top {
  width: 10rem;
  height: 5.685185rem;
  background: url('../image/share/title_bg.png') no-repeat;
  background-size: 100% 100%;
  margin: 0;
  padding: 0;
}

.share_top_div {
  width: 5rem;
  height: 5.685185rem;
}

.share_top_div>img {
  width: 3.62037rem;
  height: 4.425926rem;
  margin-top: 0.824074rem;
  margin-left: 0.824074rem;
}

.share_top_div p {
  padding: 0;
  margin: 0;
  width: 4.166667rem;
  height: 0.416667rem;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  clear: both;
  margin-left: 0.203704rem;
}

.share_top_div p.marginTop {
  margin-top: 1.481481rem;
}

.share_top_div p.marginTop_small {
  margin-top: 0.027778rem;
}

.progress-radial {
  width: 2.592593rem;
  height: 2.592593rem;
  border-radius: 50%;
  margin: 0 auto;
  margin-top: 0.537037rem;
}

.progress-radial .overlay {
  position: absolute;
  width: 1.666667rem;
  height: 1.666667rem;
  background-color: #fdffff;
  border-radius: 50%;
  margin-left: 0.462963rem;
  margin-top: 0.462963rem;
  text-align: center;
  line-height: 1.703704rem;
  font-size: 0.777778rem;
}

.circle_blueBg {
  background-color: #72a0fe;
}

.circle_orangeBg {
  background-color: #ffa06d;
}

.share_content {
  width: 9.537037rem;
  height: auto;
  margin: 0 auto;
  padding: 0;
}

.fontColor_blue {
  color: #6595f6;
}

.fontColor_orage {
  color: #ff813d;
}

.fontColor_white {
  color: #ffffff;
}

.fontColor_grey {
  color: #c2d2ff;
}

.fontColor_green {
  color: #10be5e;
}

.fontColor_greyBlack {
  color: #797979;
}

.fontSize_max {
  font-size: 0.333333rem;
}

.fontSize_small {
  font-size: 0.222222rem;
}

.fontSize_weight {
  font-weight: 800;
}

.fontNumber {
  font-family: "agenfb";
}

.left {
  float: left;
}