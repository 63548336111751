.bind {
  width: 100%;
  height: 100%;
  background-color: #cf632e;
  min-height: 18rem;
  padding-top: 0.6rem;
}

.bind_logo {
  width: 5rem;
  height: 2.8rem;
  margin: 0 auto;
}

.bind_logo img {
  width: 100%;
}

.bind form {
  position: relative;
  z-index: 5;
}

.bind_user {
  width: 8.5rem;
  height: 5.6rem;
  border: 0.03rem solid #d98a53;
  background-color: rgba(210, 115, 58, 0.7);
  margin: 0 auto;
  border-radius: 0.1rem;
}

.bind_phone {
  width: 8.5rem;
  height: 3.4rem;
  border: 0.03rem solid #d98a53;
  background-color: rgba(210, 115, 58, 0.7);
  margin: 0 auto;
  margin-top: 0.8rem;
  border-radius: 0.1rem;
}

.bind_select {
  width: 8.5rem;
  height: 2.9rem;
  border: 0.03rem solid #d98a53;
  background-color: rgba(210, 115, 58, 0.7);
  margin: 0 auto;
  margin-top: 0.4rem;
  border-radius: 0.1rem;
}

.bind_select select {
  width: 5.2rem;
  height: 0.8rem;
  line-height: 0.8rem;
  font-size: 0.4rem;
  color: #555555;
  vertical-align: middle;
  background-color: #ffffff;
  border: 0.009259rem solid #d26f2f;
  border-radius: 0.08rem;
  box-shadow: inset 0 0.009259rem 0.009259rem rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.bind_input {
  width: 100%;
  height: 2.3rem;
}

.border_margin {
  margin-top: 0.6rem;
}

.input_label {
  width: 100%;
  height: 1rem;
  font-size: 0.5rem;
  line-height: 1rem;
  color: #ffffff;
  text-align: center;
  display: block;
}

.input_text {
  margin: 0 auto;
  display: block;
}

.input_select {
  width: 5.2rem;
  height: 0.8rem;
  margin: 0 auto;
  display: block;
}

.letspace {
  letter-spacing: 0.06rem;
}

.zhspace {
  margin-left: 0.3rem;
  font-size: 0.4rem;
}

.zhspace2 {
  margin-left: 0.1rem;
  font-size: 0.4rem;
}

.bind_input .small {
  width: 6rem;
  height: 1rem;
}

.bind_input .small input {
  width: 5.6rem;
}

.bind_input .big {
  width: 7.3rem;
  height: 1rem;
}

.bind_input .big input {
  width: 6.8rem;
}

.input_text input {
  font-size: 0.4rem;
  height: 0.5rem;
  padding: 0.2rem 0.2rem;
  display: block;
  text-align: center;
  color: #555555;
  vertical-align: middle;
  background-color: #ffffff;
  border: 0.009259rem solid #d26f2f;
  border-radius: 0.08rem;
  box-shadow: inset 0 0.009259rem 0.009259rem rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.input_text input:focus {
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 0.009259rem 0.009259rem rgba(0,0,0,.075), 0 0 0.074074rem rgba(102, 175, 233, 0.6);
}

.bind_btn {
  width: 8.5rem;
  height: 2rem;
  margin: 0 auto;
  margin-top: 0.8rem;
}

.bind_btn input {
  width: 100%;
  height: 1.2rem;
  font-size: 0.5rem;
  font-weight: normal;
  line-height: 1.2rem;
  color: #f9fcf8;
  border-width: 0;
  border-radius: 0.1rem;
  box-shadow: 0 0 0.08rem rgb(113, 37, 37);
  cursor: pointer;
  background-color: #d75740;
  letter-spacing: 0.05rem;
}

.bind_msg {
  color: red;
  height: 3rem;
  line-height: 3rem;
  font-size: 0.5rem;
  text-align: center;
}

.box_bind {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0,0,0,0.5);
}

.boxs_bind {
  width: 8rem;
  height: 4rem;
  background: #fff;
  box-shadow: 0.009259rem 0.064815rem 0.166667rem 0 rgba(84, 115, 128, 0.11);
  border-radius: 0.2rem;
  position: absolute;
  left: 1rem;
  top: 5rem;
}

.box_table {
  width: 100%;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  font-size: 0.4rem;
  color: #c5112f;
}

.box_title {
  width: 100%;
  height: 1rem;
}

.box_title img {
  width: 0.6rem;
  float: right;
  padding-right: 0.2rem;
  padding-top: 0.2rem;
  cursor: pointer;
}