@font-face {
  font-family: 'agenfb';
  src: url('../font/AGENCYB.TTF');
  src: url('../font/AGENCYR.TTF');
}

.share {
  margin: 0 auto;
  width: 10rem;
  height: auto;
  padding: 0;
}

.share_link {
  width: 1rem;
  height: 1rem;
  float: right;
  position: absolute;
  top: 4.1rem;
  right: 0.2rem;
  cursor: pointer;
}

.share_link img {
  width: 1rem;
  height: 1rem;
}

#sub h3 {
  width: 100%;
  height: 0.5rem;
  font-size: 0.4rem;
  line-height: 0.2rem;
  border-bottom: 0.009259rem solid #797979;
}

#parent h3 {
  width: 100%;
  height: 0.5rem;
  font-size: 0.4rem;
  line-height: 0.2rem;
  border-bottom: 0.009259rem solid #797979;
}

.download {
  width: 0.509259rem;
  height: 0.509259rem;
  cursor: pointer;
  position: absolute;
  z-index: 100;
  margin-left: 9.259259rem;
  margin-top: 0.185185rem;
}

.share_all {
  margin: 0 auto;
  width: 10rem;
  height: auto;
  min-height: 16.666667rem;
  padding: 0;
  font-family: "Microsoft YaHei";
}

.share_topp {
  width: 10rem;
  height: 5.685185rem;
  background: url('../image/share/title_oral.png') no-repeat;
  background-size: 100% 100%;
  margin: 0;
  padding: 0;
}

.share_top_div {
  width: 5rem;
  height: 5.685185rem;
}

.share_top_div>img {
  width: 3.62037rem;
  height: 4.425926rem;
  margin-top: 0.824074rem;
  margin-left: 0.824074rem;
}

.share_top_div p {
  padding: 0;
  margin: 0;
  width: 4.166667rem;
  height: 0.416667rem;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  clear: both;
  margin-left: 0.203704rem;
}

.share_top_div p.marginTop {
  margin-top: 1.481481rem;
}

.share_top_div p.marginTop_small {
  margin-top: 0.027778rem;
}

.fontColory {
  color: #f1ac85;
}

.fontColorm {
  color: #f8e7dd;
}

.enfont {
  margin-left: 0.1rem;
  color: #141414;
}

.fontSize_max {
  font-size: 0.333333rem;
}

.fontSize_small {
  font-size: 0.222222rem;
}

.fontSize_weight {
  font-weight: 800;
}

.fontNumber {
  font-family: "agenfb";
}

.left {
  float: left;
}

.art_title {
  width: 100%;
  height: 1.5rem;
  font-size: 0.6rem;
  color: #ffffff;
  border-radius: 0.1rem;
  text-align: center;
  line-height: 1.5rem;
  font-weight: bold;
}

.art_left {
  width: 60%;
  height: 6rem;
  float: left;
}

.art_right {
  width: 40%;
  height: 6rem;
  float: left;
}

.art_right img {
  float: right;
  width: 3rem;
  padding-right: 0.2rem;
}

.art_row {
  width: 100%;
  height: 1.2rem;
  padding-left: 0.2rem;
}

.art_row_left {
  float: left;
  height: 1.2rem;
  width: 3.4rem;
}

.art_row_zh {
  font-size: 0.4rem;
  letter-spacing: 0.08rem;
}

.art_row_en {
  font-size: 0.3rem;
  margin-top: 0.1rem;
}

.art_row_left span {
  width: 100%;
  display: block;
}

.art_row_right {
  float: left;
  height: 1.2rem;
  width: auto;
  line-height: 0.5rem;
  font-size: 0.5rem;
  font-weight: 800;
}

.small_title {
  color: #000000;
  font-weight: bold;
  font-size: 0.4rem;
  margin: 0;
  margin: 0.3rem 0;
  padding: 0 0.2rem;
}

.small_title span {
  letter-spacing: 0.08rem;
  margin-right: 0.1rem;
}

.art_title span {
  font-size: 0.5rem;
}

.art_title span.en {
  margin-left: 0.2rem;
}

.non-weight {
  font-weight: normal;
  font-size: 0.4rem;
}

.non-weight img {
  width: 0.7rem;
}

.art_1 {
  width: 99%;
  height: 8.5rem;
  margin-top: 0.5rem;
  margin: 0 auto;
}

.art_1 .art_title {
  background-color: #7a83b8;
}

.art_1 .art_1_content {
  width: 100%;
  height: 6.9rem;
}

.art_2 {
  width: 99%;
  height: 4.5rem;
  margin: 0 auto;
}

.art_2 .art_title {
  background-color: #b6aFD1;
}

.art_2 .art_2_content {
  width: 100%;
  height: 3.2rem;
}

.art_3 {
  width: 99%;
  height: 4.5rem;
  margin: 0 auto;
}

.art_3 .art_title {
  background-color: #aea192;
}

.art_3 .art_3_content {
  width: 100%;
  height: 3.2rem;
}

.art_4 {
  width: 99%;
  height: 4.5rem;
  margin: 0 auto;
}

.art_4 .art_title {
  background-color: #ba9898;
}

.art_4 .art_4_content {
  width: 100%;
  height: 3.2rem;
}

.art_5 {
  width: 99%;
  min-height: 6rem;
  margin: 0 auto;
}

.art_5 .art_title {
  background-color: #8599a0;
}

.scores {
  width: 100%;
  height: auto;
}

.scores thead tr {
  height: 1rem;
}

.scores thead tr td {
  font-size: 0.4rem;
  font-weight: bold;
  text-align: center;
}

.scores tbody tr {
  height: 0.8rem;
}

.scores tbody tr td {
  text-align: center;
  font-weight: bold;
  font-size: 0.4rem;
}

.table-1 {
  width: 100%;
  height: auto;
}

.table-1 thead tr {
  height: 1.5rem;
}

.table-1 thead tr td {
  font-size: 0.4rem;
  text-align: center;
}

.table-1 tbody tr {
  height: 1.1rem;
}

.table-1 tbody tr td {
  text-align: center;
  font-weight: bold;
  width: 50%;
}

.tab_title {
  display: block;
  font-size: 0.4rem;
  margin-top: 0.1rem;
}

.hui {
  color: #9b99a2;
}

.coffee {
  color: #673434;
  font-size: 0.6rem;
}

.orange {
  color: #f16520;
  font-size: 1rem;
}

.line {
  text-decoration: underline;
  cursor: pointer;
}

.green {
  color: green;
}

.box {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0,0,0,0.5);
}

.boxs {
  width: 8rem;
  height: 5rem;
  background: #fff;
  box-shadow: 0.009259rem 0.064815rem 0.166667rem 0 rgba(84, 115, 128, 0.11);
  border-radius: 0.2rem;
  position: absolute;
  left: 1rem;
  top: 5rem;
}

.boxs table {
  font-size: 0.5rem;
  overflow-y: scroll;
}

.box_table {
  width: 100%;
  height: 3.6rem;
  overflow-y: scroll;
}

.box_title {
  width: 100%;
  height: 1rem;
}

.box_title img {
  width: 0.6rem;
  float: right;
  padding-right: 0.2rem;
  padding-top: 0.2rem;
  cursor: pointer;
}