.nodata h2 {
  width: 100%;
  height: 3rem;
  text-align: center;
  margin-top: 2rem;
}

.report {
  width: 100%;
  height: auto;
}

.static {
  width: 12rem;
  margin: 0 auto;
  min-height: 20rem;
}

.static h2 {
  width: 100%;
  height: 1rem;
  line-height: 1rem;
  font-size: 0.5rem;
}

.rowline {
  width: 100%;
  clear: both;
  font-size: 0.3rem;
  line-height: 1rem;
}

.rowline-min {
  min-height: 4rem;
}

.rowleft {
  width: 50%;
  float: left;
}

.rowline h4 {
  margin: 0;
}

.rowline ul {
  margin: 0;
  padding: 0;
}

.rowline ul li {
  list-style: none;
  height: auto;
}

.rowline ul li a {
  cursor: pointer;
  color: blue;
}

.rowline ul li div {
  width: 100%;
  height: auto;
}

.rowline ul li div a {
  width: auto;
  color: red;
  padding-left: 0.1rem;
  cursor: pointer;
}

.bottomData {
  width: 12rem;
  margin: 0 auto;
  min-height: 8rem;
  margin-bottom: 1rem;
}

.rowright {
  float: left;
  width: 50%;
}

.search {
  width: 12rem;
  height: auto;
  margin: 0 auto;
}

.search h2 {
  width: 100%;
  height: 1rem;
  line-height: 1rem;
  font-size: 0.5rem;
}

.searchIn {
  font-size: 0.3rem;
  margin-top: 1rem;
}

.searchIn button {
  width: 3.4rem;
  height: 0.6rem;
  margin-left: 1.5rem;
}

.searchData {
  width: 100%;
  height: 2.3rem;
}

.searchCourse {
  font-size: 0.3rem;
  height: 0.5rem;
  line-height: 0.5rem;
}

.searchCourse span {
  width: 5.5rem;
  display: inline-block;
}

.searchRemark {
  width: 100%;
  height: auto;
  font-size: 0.4rem;
}